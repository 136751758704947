import { getAxiosDefault } from 'apiClient/config/apiAxiosConfig';
import { getContentTypeOfBase64 } from 'utils/fileUtils';
import { notNullNotUndefined } from 'utils/objectUtils';

export const getWorkspaceEac = (workspaceId) => {
    notNullNotUndefined(workspaceId)
    return getAxiosDefault().get(`/access/workspaces/${workspaceId}/eac`)
}

export const getWorkspaceAcl = (workspaceId) =>
    getAxiosDefault().get(`/access/workspaces/${workspaceId}/acl`)

export const deleteWorkspace = (workspaceId) =>
    getAxiosDefault().delete(`/access/workspaces/${workspaceId}`)

export const getWorkspaceSubscriptionInfo = (workspaceId) =>
    getAxiosDefault().get(`/access/workspaces/${workspaceId}/subscriptionInfo`)

export const getWorkspaceSubscriptionPlanComparison = (workspaceId) =>
    getAxiosDefault().get(`/access/workspaces/${workspaceId}/subscriptionPlanComparison`)

export const getWorkspaceSubscriptionPlanComparisonCurrentPlan = (workspaceId) =>
    getAxiosDefault().get(`/access/workspaces/${workspaceId}/subscriptionPlanComparison/currentPlan`)

export const paddleChangeSubscription = (workspaceId, plan, period) => {
    notNullNotUndefined(workspaceId)
    notNullNotUndefined(plan)
    notNullNotUndefined(period)
    return getAxiosDefault().post(`/access/workspaces/${workspaceId}/subscriptionInfo/paddle/changeSubscription?plan=${plan}&period=${period}`)
}

export const getPaymentPaddleSettings = (workspaceId) =>
    getAxiosDefault().get(`/access/workspaces/${workspaceId}/payment/paddleSettings`)

export const createInvitationToken = (workspaceId) =>
    getAxiosDefault().post(`/access/workspaces/${workspaceId}/createInvitationToken`)

export const getInvitationTokenStatus = (invitationTokenBase64) => {
    notNullNotUndefined(invitationTokenBase64)
    return getAxiosDefault().get(`/access/workspaces/invitationTokenStatus/${encodeURIComponent(invitationTokenBase64)}`)
}

export const removeUserFromWorkspace = (workspaceId, userAccountId) =>
    getAxiosDefault().post(`/access/workspaces/${workspaceId}/users/${userAccountId}/removeUser`)

export const createWorkspacePublic = () => getAxiosDefault().post(`/access/workspaces/public`)


export const uploadAssetFromBase64 = (workspaceId, base64, props) => {
    notNullNotUndefined(base64)
  
    return fetch(base64)
      .then(res => res.blob())
      .then(blob => {
        const fd = new FormData()
  
        const file = new File([blob], 'asset', {type: getContentTypeOfBase64(base64)})
        fd.append('file', file)
  
        return getAxiosDefault().post(`/access/workspaces/${workspaceId}/assets`, fd, {
          ...props
        })
    })
  
}

export const createPwResetUrl = (userId) => getAxiosDefault().post(`/access/users/${userId}/createPwResetUrl`)