import { Box, IconButton } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { forwardRef } from "react";
import CloseIcon from '@mui/icons-material/Close';


const useStyles = makeStyles((theme) => ({
    root: {
        background: '#fff',
        padding: theme.spacing(3),
        borderRadius: '10px',
        width: '100%',
        position: 'relative',
        boxShadow: '-20px 20px 120px 0px rgb(35 44 79 / 7%)',
    },
    fullHeight: {
        height: '100%',
    },
    fullWidthStyleOnXs: {
        [theme.breakpoints.down('sm')]: {
            borderRadius: '0',
            border: 'none',
        }
    }
  }));

export const CardNiceSmoothShadow = forwardRef((props, ref) => {

    const classes = useStyles()

    const { children, fullHeight, fullWidthStyleOnXs, onCloseClick, style } = props

    const classNames = [classes.root]
    if (fullHeight === true) {
        classNames.push(classes.fullHeight)
    }
    if (props.className) {
        classNames.push(props.className)
    }
    if (fullWidthStyleOnXs === true) {
        classNames.push(classes.fullWidthStyleOnXs)
    }

    return (
        <Box ref={ref} className={classNames.join(' ')} color="text.secondary" style={style}>
            {onCloseClick && (
                <Box position="absolute" style={{right: '10px', top: '10px'}}>
                    <IconButton onClick={onCloseClick} size="large"><CloseIcon /></IconButton>
                </Box>
            )}
            {children}
        </Box>
    );

})