import { useWindowEvent } from 'modules/picasso-ui/state/useWindowEvent';
import { useState } from 'react';
import { ProgressDialog } from 'components/dialog/ProgressDialog';

const showEventName = 'myapp.showProgressIndicator';
const hideEventName = 'myapp.hideProgressIndicator';

export const AppProgressIndicator = () => {
    
    const [open, setOpen] = useState(false);
    const [progress, setProgress] = useState();

    useWindowEvent(showEventName, (e) => {
        setOpen(true);
        if (e.message) {
            setProgress({
                message: e.message,
                variant: e.variant,
            })
        } else {
            setProgress(null)
        }
    });

    useWindowEvent(hideEventName, () => {
        setOpen(false);

    });

    return (
        <ProgressDialog open={open} message={progress?.message} variant={progress?.variant} />
    )
}

export const showAppProgressIndicator = (message, variant) => {
    let event = new Event(showEventName);
    event.message = message;
    event.variant = variant;
    window.dispatchEvent(event);
}

export const hideAppProgressIndicator = () => {
    let event = new Event(hideEventName);
    window.dispatchEvent(event);
}