import { getWorkspaceAcl, getWorkspaceEac, uploadAssetFromBase64 } from 'modules/plinzip/api/workspacesApi';
import { useSignedIn } from 'modules/react-auth';
import { useTranslation } from 'next-i18next';
import useSWR from 'swr';
import { dynamicSort } from 'utils/arrayUtils';
import { notNullNotUndefined } from 'utils/objectUtils';
import { handleResponseDataGetContent, useResolvedItemV2 } from 'utils/useResolvedV2';
import { getWorkspace } from '../api/accessApi';
import { useApp } from '../useApp';
import { getTranslationKeyForUserRole } from './workspaceUtils';
import { hideAppProgressIndicator, showAppProgressIndicator } from 'modules/picasso-ui/main/AppProgressIndicator';
import { AxiosResponse } from 'axios';
import { SavedAsset } from 'modules/picasso-ui/form/assets/AssetTypes';
import { UploadableAsset } from 'modules/picasso-ui/form/assets/AssetsEdit';

export const useWorkspaceMy = () => {

    const { me } = useSignedIn()

    const workspaceId = me?.accessContext?.workspace?.workspaceId

    const eacResolved = useResolvedItemV2(workspaceId?`workspaces/${workspaceId}/eac`:null, ()=>getWorkspaceEac(workspaceId),{revalidateOnFocus: false})

    return { 
        workspaceId: me?.accessContext?.workspace?.workspaceId,
        workspace: me?.accessContext?.workspace,
        reloadWorkspace: me?.reloadMe,
        eac: eacResolved.item
    }

}

export const useWorkspace = (workspaceId) => {

    const workspaceResolved = useResolvedItemV2(workspaceId ? `/workspaces/${workspaceId}/` : null, () => getWorkspace(workspaceId), {
        revalidateOnFocus: false,
        refreshInterval: 30000,
    })

    const mutateUsers = () => {
        return workspaceResolved.mutate()
    }

    const getWorkspaceUsers = (workspace) => {
        notNullNotUndefined(workspace)

        if (workspace.users) {
            const sorted = [...workspace.users].sort(dynamicSort('createdAt'))
            return sorted
        } else {
            return []
        }
    }

    if (!workspaceId || !workspaceResolved.item) {
        return {
            ready: false,
        } as { ready?: boolean, users?: any[]}
    }

    const users = getWorkspaceUsers(workspaceResolved.item)
    
    const res = {
        ready: true,
        users,
        mutateUsers,
    }

    return res
}

export interface DisplayableGroup {
    title: string
    permissions: DisplayablePermission[]
}

export interface DisplayablePermission {
    permission: string
    title: string
}

export const useWorkspaceAcl = (workspaceId): Map<string, DisplayableGroup> => {

    const { app } = useApp()

    //@ts-ignore
    const { t } = useTranslation(app.i18nNamespaces)

    //@ts-ignore
    const i18nNamespaceDefault = app.i18nNamespaceDefault

    const { data: acl } = useSWR<Map<string, DisplayableGroup>>(`/workspaces/${workspaceId}/acl`, ()=>getWorkspaceAcl(workspaceId).then(handleResponseDataGetContent).then((data)=>{

        // Map to DisplayablePermission and include title
        const map = new Map<string, DisplayableGroup>()
        for (const [group, permissions] of Object.entries<string[]>(data)) {
            map.set(group, {
                //@ts-ignore
                title: t('yoio:'+getTranslationKeyForUserRole(group)),
                permissions: permissions.map(p=>{

                    const titleKey = `${i18nNamespaceDefault}:app.permissions.${p}.title`
                    let title = i18nNamespaceDefault ? (t(titleKey) || null):null
                    if (title === `app.permissions.${p}.title`) {

                        // If no match, lookup in namespace yoio
                        title = t(`yoio:access.permissions.${p}.title`)??null

                        if (title === null || title === `access.permissions.${p}.title`) {
                            // If still no match, use the permission string
                            title = p
                        }
                    }

                    return {
                        permission: p,
                        title,
                    }
                })
            })
        }
        return map
    }), {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false
    })

    return acl
}

export const useWorkspaceEdit = (workspaceId: string) => {
    notNullNotUndefined(workspaceId)

    const handleAssetUploadSuccess = (uploads: AxiosResponse<SavedAsset>[]):Promise<SavedAsset[]> => {
        const assets:SavedAsset[] = uploads.map((v)=>{
            return { ...v.data }
        });
        hideAppProgressIndicator()
        return Promise.resolve(assets)
            .catch((e)=>{
                console.error(e)
                hideAppProgressIndicator
                ()
                throw e
            })
    } 

    const uploadAssets = (assets: UploadableAsset[]): Promise<AxiosResponse<SavedAsset>[]> => {

        showAppProgressIndicator('Uploading', 'linear')
        let uploads = assets.map((a, idx)=>{
            return uploadAssetFromBase64(workspaceId, a.data_url, null)
        })
        return Promise.all(uploads).catch((e)=>{hideAppProgressIndicator(); throw e})
    }

    return { uploadAssets, handleAssetUploadSuccess }

} 