import { Box, Button, ButtonProps, Typography } from "@mui/material";
import { PricingPlanFeatureItem } from "modules/landingPage/component/pricing/PricingPlans"
import { ElementLink } from "modules/picasso-ui/link/LinkNormal"
import Image from "next/image";
import { FC } from "react";
import { appRoutes } from "utils/appRoutes"

const styleDef = {
    featuresStartText: theme=>({
        color: theme.palette.text.primary,
        fontWeight: '600',
        marginBottom: theme.spacing(1),
    })
}

export interface UpgradeProposalProps {
    features?: any[]
    featuresStartText?: string
    incentiveAction?: 'signup' | 'upgrade'
    showIncentiveAction?: boolean
    incentiveText?: string
    showImage?: boolean
    incentiveBtnProps?: ButtonProps
}

export const UpgradeProposal: FC<UpgradeProposalProps> = ({features, featuresStartText, incentiveAction = 'upgrade', showIncentiveAction, incentiveText, showImage, incentiveBtnProps}) => {

    const featuresCount = features?.length

    const renderFeatures = (items) => {
        return items.map((feature)=>{
            //@ts-ignore
            return <PricingPlanFeatureItem title={feature.title} key={feature.feature}/>
        })
    }

    const renderIncentiveAction = (props) => {

        const fullWidth = props?.fullWidth ?? true

        return (
            <>
            {showIncentiveAction !== false &&
                <>
                    {incentiveAction === 'signup' &&
                        <ElementLink target="_blank" href={appRoutes.yoio.access.signup.buildPath()}>
                            <Button 
                                fullWidth={fullWidth}
                                color="primary"
                                variant="contained"
                                size="large"
                            >
                                {incentiveText}
                            </Button>
                        </ElementLink>
                    }
                    {incentiveAction === 'upgrade' &&
                            <Button 
                                fullWidth={fullWidth}
                                color="primary"
                                variant="contained"
                                size="large"
                                onClick={(e)=>{
                                    incentiveBtnProps?.onClick?.(e)
                                }}
                            >
                                {incentiveText}
                            </Button>
                    }
                </>
            }
            </>
        )
    }

    const mainFeature = featuresCount === 1 ? features[0] : null

    return (
        <div>
            <Box display="flex" sx={theme=>({
                flexDirection: 'column',
                [theme.breakpoints.up('md')]: {
                    alignItems: 'center',
                    flexDirection: 'row',
                }
            })}>
                {showImage && <Image src="/yoio/upgrade-default.svg" width="200px" height="200px" unoptimized/>}
                <Box sx={(theme)=>({
                    [theme.breakpoints.up('md')]: {
                        marginLeft: showImage? theme.spacing(6) : null,
                        paddingBottom: theme.spacing(2),
                    },
                    [theme.breakpoints.down('md')]: {
                        textAlign: 'center',
                        marginTop: showImage? theme.spacing(3) : null,
                    },
                })}>
                    {mainFeature ? 
                        <>
                            <Typography sx={{fontSize: '20px', fontWeight: 600}}>{mainFeature.upgradeIncentiveTitle}</Typography>
                            <Typography sx={{maxWidth: '250px',marginTop: '6px'}}>{mainFeature.upgradeIncentiveText}</Typography>
                        </>
                        :
                        <>
                            {featuresStartText && 
                                <Typography sx={styleDef.featuresStartText}>{featuresStartText}</Typography>
                            }
                            {features && 
                                renderFeatures(features)
                            }
                        </>
                    }

                    <Box mt={2}>
                        {renderIncentiveAction({
                            fullWidth: false,
                            ...incentiveBtnProps
                        })}
                    </Box>
                </Box>
            </Box>

{/*             {featuresCount > 1 &&
                <>
                    <Box display="flex" sx={theme=>({
                        marginTop: theme.spacing(6),
                        flexDirection: 'column',
                        [theme.breakpoints.up('md')]: {
                            alignItems: 'center',
                            flexDirection: 'row',
                        }
                    })}>
                            <Image src="/yoio/upgrade-default.svg" width="200px" height="200px" unoptimized/>
                            <Box m="auto" maxWidth="300px">


                                {featuresStartText && 
                                    <Typography className={classes.featuresStartText}>{featuresStartText}</Typography>
                                }
                                {features && 
                                    renderFeatures(features)
                                }
                            </Box>
                    </Box>

                    {showIncentiveAction !== false && 
                        <Box>
                            <Box mt={3} ml="auto" mr="auto" maxWidth="300px">
                                {renderIncentiveAction()}
                            </Box>
                        </Box>
                    }

                </>
            } */}

        </div>
    )

}