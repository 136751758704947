import { useState } from 'react';
import { Box, Divider, Snackbar, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import MuiAlert from '@mui/material/Alert';
import objectUtils from 'utils/objectUtils';
import { useWindowEvent } from 'modules/picasso-ui/state/useWindowEvent';
import { UpgradeProposal } from 'modules/yoio/component/UpgradeProposal';
import { usePlanPickerDialog } from 'modules/yoio/access/subscription/PlanPickerDialog';

const useStyles = makeStyles((theme)=>({
    alert: {
        backgroundColor: '#fff',
        color: theme.palette.text.primary,
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    text: {
        color: theme.palette.text.primary,
        fontWeight: '600',
        fontSize: '20px',
    },
}))

/**
 *
 * @param {} param0
 */
export default function AppAlertsPlanUpgrade() {
   
    const classes = useStyles()
   
    const [message, setMessage] = useState(null);

    const { component, openDialog } = usePlanPickerDialog()

    useWindowEvent('myapp.messageQuota', (e) => {
        handleMessage(e.value);
    });

    const handleMessage = (message) => {
        if (objectUtils.isNullOrUndefined(message)) {
            return;
        }

        //fail if no text
        objectUtils.notNullNotUndefined(message.text);

        //fail if no severity
        objectUtils.notNullNotUndefined(message.severity);

        setMessage(message);
    };

    const handleClose = () => {
        setMessage(null);
    };

    function Alert(props) {
        return <MuiAlert 
        {...props} 
        sx={(theme)=>({
            boxShadow: '0 50px 100px -20px rgba(50,50,93,0.25),0 30px 60px -30px rgba(0,0,0,0.3)',
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: '1em',
        })}
        />;
    }

    return (
        <>
            <Snackbar 
                open={message !== undefined && message !== null} 
                autoHideDuration={message?.duration || 30000} 
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                >
                <div>
                {message && (
                    <Alert 
                        //onClose={handleClose} 
                        className={classes.alert} 
                        icon={false}>
                        <div>
                            <Box mb={3}>
                                <Typography className={classes.text}>
                                    {message.upgradeProposal?.title ? message.upgradeProposal?.title : message.text}
                                </Typography>
                                <Divider sx={{marginTop: '12px'}} />
        {/*                         {message.limit && 
                                    <Box mt={1} color="text.secondary">
                                        <Typography className={classes.limit}>
                                            Limit: {message.limit}
                                        </Typography>
                                    </Box>
                                } */}
                            </Box>

                            {message.upgradeProposal && (
                                <Box pt={6} pb={6} pr={3}>
                                    <UpgradeProposal {...message.upgradeProposal} showImage 
                                    incentiveBtnProps={{
                                        onClick: ()=>{handleClose(); openDialog();}
                                    }} 
                                    />
                                </Box>
                            )}
                        </div>
                    </Alert>
                )}
                </div>
            </Snackbar>
            {component}
        </>
    );
}
