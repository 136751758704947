import { Box, Container, Divider, Grid, Tooltip, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { MultilineText } from "modules/picasso-ui/present/MultilineText"
import { useTranslation } from "next-i18next"
import Image from "next/image"
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { CardNiceSmoothShadow } from "modules/picasso-ui/present/CardNiceSmoothShadow"
import { MainIncentiveLink } from "../saas/MainIncentiveLink";
import { appRoutes } from "utils/appRoutes";
import { Highlights } from "modules/picasso-ui/present/specials/Highlights";

const useStyles = makeStyles((theme)=>({
    plans: {
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'center',
        },
        height: '100%',
    },
    planGridItem: {
        height: '100%',
    },
    planCont: {
        //width: '380px',
        minWidth: '275px',
        '&+$planCont': {
            marginLeft: theme.spacing(3),
            [theme.breakpoints.down('md')]: {
                marginLeft: '0px',
            }
        }
    },
    planCard: {
        height: '100%',
    },
    planTitle: {
        color: theme.palette.text.primary,
        fontWeight: '600',
        fontSize: '16px',
    },
    planTagline: {
        color: 'rgb(63 84 107 / 85%)',
        fontSize: '16px',
        display: 'inline-block',
        height: '34px',
    },
    priceTag: {
        color: '#346ecf',
        //backgroundColor: '#f1f1f1',
        //borderRadius: '30px',
        fontSize: '40px',
        display: 'inline-block',
        //padding: '6px 25px',
        marginTop: '-6px',
        fontWeight: '500',

    },
    iconContainer: {
        paddingTop: '4px',
        //width: '30px',
        display: 'flex',
        paddingRight: '7px',
    },
    featureBox: {
        display: 'flex',
        alignItems: 'flex-start',
        paddingTop: theme.spacing(1),
        paddingBottom: '1px',
    },
    featureTitle: {
        fontSize: '15px',
        color: '#778291',
    },
    featureTitleHighlighted: {
        fontWeight: '600',
        color: theme.palette.text.primary,
    },
    tooltip: {
        fontSize: '16px',
        padding: '12px',
    },
    infoIcon: {
        fontSize: '12px',
    },
}))

export const PricingPlans = ({items, highlights, noCreditCardRequired}) => {

    const classes = useStyles()

    const { t } = useTranslation('yoio')

    const renderItems = () => items.map((i,idx)=>{

        return (
            <Box key={idx} mb={4} textAlign="center" className={classes.planCont}>
                <PricingPlanItem 
                    href={appRoutes.yoio.access.getStartedWithPricingPlan.buildPath()}
                    {...i}
                />
            </Box>
        )
    })

    return (
        <Box mb={10}>
            {highlights && (
                <Box mt={8} mb={10}>
                    <Container maxWidth="md">
                        <Grid container justifyContent="center">
                            <Highlights items={highlights} />
                        </Grid>
                    </Container>
                </Box>
            )}
            <div className={classes.plans} id="select-plan">
            {/*  <Grid container spacing={6} justifyContent="center" alignItems="stretch"> */}
                    {renderItems()}
            {/*  </Grid> */}
            </div>
            {noCreditCardRequired === true && (
                <Box color="text.secondary" pt={2} pb={2} textAlign="center">
                    <Typography>{t('yoio:access.plans.noCreditCardRequiredHelperText')}</Typography>
                </Box>
            )}
        </Box>
    )

}

export const PricingPlanFeatureItem = ({title, info, highlight}) => {

    const classes = useStyles()

    const tooltip = info || ''

    const titleClassNames = [classes.featureTitle]
    if (highlight === true) {
        titleClassNames.push(classes.featureTitleHighlighted)
    }

    return (
        <Tooltip title={tooltip} placement="top" arrow classes={{tooltip: classes.tooltip}}>
            <div className={classes.featureBox}>
                <div className={classes.iconContainer}><FeatureIncludedIcon /></div>
                <Box textAlign="left">
                    <Typography className={titleClassNames.join(' ')}>{title} {info && <InfoOutlinedIcon className={classes.infoIcon}/>}</Typography>
                </Box>
            </div>
        </Tooltip>
    )
}

const FeatureIncludedIcon = () => <Image layout="fixed" src={'/icons/check-simple-blue.svg'} width={15} height={15} alt="feature included" />

export const PricingPlanItem = ({tagline, title, price, href, features, style, buttonText}) => {

    const classes = useStyles()

    const { i18n, t } = useTranslation('yoio')

    const currency = i18n?.language === 'de' ? 'EUR' : 'USD'
    const currencySign = currency === 'EUR' ? '€' : '$'

    const renderFeatures = (features) => {
        return features.map((f, idx)=><PricingPlanFeatureItem {...f} key={idx}/>)
    }

    return (
        <CardNiceSmoothShadow className={classes.planCard} fullHeight={true} style={style}>
            <Box display="flex" flexDirection="column" style={{height: '100%'}} pb={2} pl={2} pr={2}>
                <Box mb={2}>

                </Box>
                {price && 
                    <Box pt={2} pb={1}>
                        <Box className={classes.priceTag}>{currencySign}{price[currency]}</Box>
                        <Box color="text.secondary" mt={'-10px'}>{t('yoio:access.plans.paymentPeriod.monthly.titlePer')}</Box>
                    </Box>
                }
                {title && 
                    <Box mt={2}>
                        <Typography className={classes.planTitle}>
                            {title}
                        </Typography>
                    </Box>
                }
                {tagline && (
                    <Box mt={1}>
                        <Typography className={classes.planTagline}>
                            <MultilineText>
                                {tagline}
                            </MultilineText>
                        </Typography>
                    </Box>
                )}
                {(price || title || tagline) && 
                    <Box pt={3} pb={3} textAlign="center">
                        <Box style={{width: '120px', margin: 'auto'}}>
                            <Divider />
                        </Box>
                    </Box>
                }
                <Box pl={1} pr={1} flexGrow={1}>
                    {renderFeatures(features)}
                </Box>
                {href &&
                    <Box mt={5}>
                        <MainIncentiveLink 
                            buttonProps={{
                                size: 'large'
                            }}
                            fullWidth={false}
                            title={buttonText || t('yoio:access.signup.startForFreeText')} 
                            showArrow={false}
                            elevateHigh={false}
                            liftOnHover={true}
                            href={href} />
                    </Box>
                }
            </Box>
        </CardNiceSmoothShadow>
    )

}