import { notNullNotUndefined } from "utils/objectUtils"

export const hasEntityPermission = (entity, permission) => {
    if (!entity?.eacEffective) {
        return false
    }

    return hasPermissionInEac(entity.eacEffective, permission)
}

export const hasPermissionInEac = (eac, permission) => {
    notNullNotUndefined(eac)
    notNullNotUndefined(permission)

    return eac.permissions?.list !== undefined && eac.permissions.list.includes(permission);
}

export const hasFeatureInEac = (eac, feature) => {
    notNullNotUndefined(eac)
    notNullNotUndefined(feature)

    return eac.features?.includes(feature)
}

export const hasGroupInEac = (eac, group) => {
    notNullNotUndefined(eac)
    notNullNotUndefined(group)

    return eac.groups?.list !== undefined && eac.groups.list.includes(group);
}

export const PermissionEntityBorder = ({eac, entity, permission, children, errorState}) => {
    
    const hasPermission = entity ? 
        hasEntityPermission(entity, permission)
        :
        (eac ? hasPermissionInEac(eac, permission) : false)

    errorState = errorState || <></>

    return (
        <>
            {hasPermission === true ? 
                children
                : 
                errorState
            }
        </>
    )
}