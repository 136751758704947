import Dialog from '@mui/material/Dialog';
import { CircularProgress, DialogContent, Box, Typography, LinearProgress } from '@mui/material';

export const ProgressDialog = ({ open, message, variant }) => {

  variant = variant || 'circular'

  return (
    <Dialog open={open}>
        <DialogContent>
            <Box p={2}>
                <Box textAlign="center" mb={2}>
                  {variant === 'circular' && <CircularProgress />}
                  {variant === 'linear' && <LinearProgress />}
                </Box>
                <Box display="flex" justifyContent="center">
                  {message !== undefined && <Typography variant="h3">{message}</Typography>}
                </Box>
            </Box>
        </DialogContent>
    </Dialog>
  );
};
