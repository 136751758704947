import { onResponseError } from "apiClient/common/responseErrorHandler";
import alerts from "modules/yoio/state/alerts";
import { notNullNotUndefined } from "./objectUtils";

export const getContentTypeOfBase64 = (base64) => {
    notNullNotUndefined(base64)
    return base64.split(';')[0].split(':')[1];
}

export const getFileEndingOfBase64 = (base64) => {
    notNullNotUndefined(base64)

    let parts = base64.split(",");
    let extension;

    switch (parts[0]) {
        case "data:image/jpeg;base64":
            extension = "jpg";
            break;
        case "data:image/png;base64":
            extension = "png";
            break;
        default:
            return null
    }

    return extension;

}

export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export const download = (file, callback, options) => {
    var request = new XMLHttpRequest();
    request.open('GET', file);
    //request.addEventListener('load', log('load ' + file));
    if (options?.onError) {
        request.addEventListener('error', options.onError)
    }
    if (options?.onProgress) {
        request.addEventListener('progress', options.onProgress)
    }
    if (options?.loadstart) {
        request.addEventListener('loadstart', options.loadstart)
    }
    if (options?.loadend) {
        request.addEventListener('loadend', options.loadend)
    }
    request.addEventListener('load', function () {
        if (request.status && !request.status.toString().startsWith('2')) {
            let error = null
            try {
                if (request.responseText) {
                    const errorFromApi = JSON.parse(request.responseText)
                    //pseudo axios object (because error handler expects axios structured response)
                    const errorLikeAxios = {
                        response: {
                            status: request.status,
                            data: errorFromApi
                        }
                    }
                    error = errorLikeAxios
                    onResponseError(errorLikeAxios)
                }  else {
                    alerts.error('unknown error. please try again in a few moments.')
                }
            } catch (error) {
                alerts.error('unknown error')
            }
            options?.onError(error)
        } else {
            callback(request.response);
        }
    });
    request.addEventListener('readystatechange', () => {
        if(request.readyState == 2) {
            if(request.status == 200) {
                request.responseType = 'blob'
            } else {
                request.responseType = 'text'
            }
        }
    })
    options?.configure?.(request)
    request.send();
  }
  
export const save = (object, mime, name) => {
    var a = document.createElement('a');
    var url = URL.createObjectURL(object);
    a.href = url;
    a.download = name;
    a.click();
}